import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import DataSpinners from "./Spinner";

const Landing = React.lazy(() => import("./NewLanding/Landing/Landing"));
const Login = React.lazy(() => import("./Login"));
const Signup = React.lazy(() => import("./Signup"));
const Wallet = React.lazy(() => import("./Wallet"));
const Events = React.lazy(() => import("./Events"));
const Aboutus = React.lazy(() => import("./NewLanding/AboutUs/About_us"));
const Community = React.lazy(() => import("./NewLanding/Community/Community"));
const Ecosystem = React.lazy(() => import("./Ecosystem"));
const Contactus = React.lazy(() => import("./NewLanding/Contactus/Contact_us"));
const QuestionFaq = React.lazy(() => import("./QuestionFaq"));
const Course = React.lazy(() => import("./Course"));
const MerchantDashboard = React.lazy(() => import("./MerchantDashboard/MerchantDashboard"));
const AdminDashboard = React.lazy(() => import("./Admin panelTelegram/AdminDashboard"));
const Referral = React.lazy(() => import("./Referral"));
const Assessment30day = React.lazy(() => import("./Assessment30day"));
const AdminLogin = React.lazy(() => import("./Admin panel/AdminLogin"));
const MerchantLogin = React.lazy(() => import("./MerchantDashboard/MerchantLogin"));
const Whitepaper = React.lazy(() => import("./NewLanding/Whitepaper/White_paper"));
const MyQuery = React.lazy(() => import("./User Dashboard 2/UserQuery"));
const Redirect = React.lazy(() => import("./Redirect"));
const Whitepaperpdf = React.lazy(() => import("./NewLanding/Whitepaper/Paperpdf"));

// TELEGRAM UI
const Homescreen = React.lazy(() => import("./Telegram UI/Homescreen"));
const Langandtime = React.lazy(() => import("./Telegram UI/Langandtime"));
const Avator = React.lazy(() => import("./Telegram UI/Avator"));
const Notification = React.lazy(() => import("./Telegram UI/Notification"));
const Dashboard = React.lazy(() => import("./Telegram UI/Dashboard2"));
const DailyReward = React.lazy(() => import("./Telegram UI/DailyReward"));
const DailyReward2 = React.lazy(() => import("./Telegram UI/DailyReward2"));
const Profile = React.lazy(() => import("./Telegram UI/Profile"));
const Invitescreen = React.lazy(() => import("./Telegram UI/Invitescreen"));
const DailyBonus = React.lazy(() => import("./Telegram UI/DailyBonus"));
const Connectwallet = React.lazy(() => import("./Telegram UI/Connectwallet"));
const Instructions = React.lazy(() => import("./Telegram UI/Instructions"));
const Assessment = React.lazy(() => import("./Telegram UI/Assessment"));
const Captcha = React.lazy(() => import("./Telegram UI/Captcha"));
const VerifyEmail = React.lazy(() => import("./Telegram UI/VerifyEmail"));
const Welcome = React.lazy(() => import("./Telegram UI/Welcome"));
const Leaderboard = React.lazy(() => import("./Telegram UI/Leaderboard"));
const ErrorWifi = React.lazy(() => import("./Telegram UI/ErrorWifi"));
const Errorwrong = React.lazy(() => import("./Telegram UI/Errorwrong"));
const Errormaintenance = React.lazy(() => import("./Telegram UI/Errormaintenance"));
const PageNotfound = React.lazy(() => import("./Telegram UI/PageNotfound"));
const Userguidance = React.lazy(() => import("./Telegram UI/Userguidance/Userguidance"));
const Aispace = React.lazy(() => import("./Telegram UI/Aispace"));
const Aivault = React.lazy(() => import("./Telegram UI/AIFactvault/Aivault"));
const ClaimRewards = React.lazy(() => import("./Telegram UI/ClaimRewards"));
const ConfirmSubscribe = React.lazy(() => import("./Telegram UI/Subscription/ConfirmSubscribe"));
const WithdrawRewards = React.lazy(() => import("./Telegram UI/Subscription/withdraw"));
const AddReferrer = React.lazy(() => import("./Telegram UI/Subscription/AddReferrer"));
const MyRewards = React.lazy(() => import("./Telegram UI/MyRewards"));
const Learning = React.lazy(() => import("./Telegram UI/AILearning/Learning"));
const Joiningbonus = React.lazy(() => import("./Telegram UI/Joiningbonus"));



// FARMING
const Farming = React.lazy(() => import("./Telegram UI/Farming/Farming"));
const Play = React.lazy(() => import("./Telegram UI/Farming/Play"));
const Discover = React.lazy(() => import("./Telegram UI/Farming/Discover"));
const Rewards = React.lazy(() => import("./Telegram UI/Farming/Rewards"));
const Spin = React.lazy(() => import("./Telegram UI/Farming/Spin"));
const AiFarming = React.lazy(() => import("./Telegram UI/Farming/AiFarming"));
const BoostFinal = React.lazy(() => import("./Telegram UI/Farming/BoostFinal"));
const FarmingTimer = React.lazy(() => import("./Telegram UI/Farming/FarmingTimer"));
const GameLeaderboard = React.lazy(() => import("./Telegram UI/Farming/GameLeaderboard"));
const UpgradeFinal = React.lazy(() => import("./Telegram UI/Farming/UpgradeFinal"));
const Taptoset = React.lazy(() => import("./Telegram UI/Farming/Taptoset"));
const Game = React.lazy(() => import("./Telegram UI/Farming/Game"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  if (isLoading) {
    return <DataSpinners />;
  }

  return (
    <div className="App">
      <Suspense fallback={<DataSpinners />}>
        <Routes>
        <Route path="/" element={<Landing />} />
          <Route path="/Community" element={<Community />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Login" element={<Landing />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Wallet" element={<Wallet />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Ecosystem" element={<Ecosystem />} />
          <Route path="/QuestionFaq" element={<QuestionFaq />} />
          <Route path="/Course" element={<Course />} />
          <Route path="/MerchantDashboard" element={<MerchantDashboard />} />
          <Route path="/AdminDashboard" element={<AdminDashboard />} />
          <Route path="/Referral" element={<Referral />} />
          <Route path="/Assessment30day" element={<Assessment30day />} />
          <Route path="/AdminLogin" element={<AdminLogin />} />
          <Route path="/MerchantLogin" element={<MerchantLogin />} />
          <Route path="/Whitepaper" element={<Whitepaper />} />
          <Route path="/Whitepaperpdf" element={<Whitepaperpdf />} />
          <Route path="/Redirect" element={<Redirect />} />

          {/* TELEGRAM */}
          <Route path="/Homescreen/:chatId/:referral_code/:community" element={<Homescreen />} />
          <Route path="/Homescreen/:chatId/:referral_code" element={<Homescreen />} />
          <Route path="/Homescreen/:chatId" element={<Homescreen />} />
          <Route path="/Langandtime" element={<Langandtime />} />
          <Route path="/Avator" element={<Avator />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/DailyReward" element={<DailyReward />} />
          <Route path="/DailyReward2" element={<DailyReward2 />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Invitescreen" element={<Invitescreen />} />
          <Route path="/DailyBonus" element={<DailyBonus />} />
          <Route path="/Connectwallet" element={<Connectwallet />} />
          <Route path="/Instructions" element={<Instructions />} />
          <Route path="/Assessment" element={<Assessment />} />
          <Route path="/Captcha" element={<Captcha />} />
          <Route path="/VerifyEmail" element={<VerifyEmail />} />
          <Route path="/Welcome" element={<Welcome />} />
          <Route path="/Leaderboard" element={<Leaderboard />} />
          <Route path="/ErrorWifi" element={<ErrorWifi />} />
          <Route path="/Errorwrong" element={<Errorwrong />} />
          <Route path="/Errormaintenance" element={<Errormaintenance />} />
          <Route path="/PageNotfound" element={<PageNotfound />} />
          <Route path="/Aispace" element={<Aispace />} />
          <Route path="/Aivault" element={<Aivault />} />
          <Route path="/ClaimRewards" element={<ClaimRewards />} />
          <Route path="/ConfirmSubscribe" element={<ConfirmSubscribe />} />
          <Route path="/WithdrawRewards" element={<WithdrawRewards />} />
          <Route path="/AddReferrer" element={<AddReferrer />} />
          <Route path="/MyRewards" element={<MyRewards />} />
          <Route path="/Learning" element={<Learning />} />
          <Route path="/Joiningbonus" element={<Joiningbonus />} />

          {/* FARMING */}
          <Route path="/Farming/Farming" element={<Farming />} />
          <Route path="/Farming/Play" element={<Play />} />
          <Route path="/Farming/Discover" element={<Discover />} />
          <Route path="/Farming/Rewards" element={<Rewards />} />
          <Route path="/Farming/Spin" element={<Spin />} />
          <Route path="/Farming/AiFarming" element={<AiFarming />} />
          <Route path="/Farming/Boost" element={<BoostFinal />} />
          <Route path="/Farming/FarmingTimer" element={<FarmingTimer />} />
          <Route path="/Farming/GameLeaderboard" element={<GameLeaderboard />} />
          <Route path="/Farming/UpgradeFinal" element={<UpgradeFinal />} />
          <Route path="/Farming/Taptoset" element={<Taptoset />} />
          <Route path="/Farming/Game" element={<Game />} />
          <Route path="/Userguidance" element={<Userguidance />} />



        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
